.contact-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.neonText {
  color: #e006e0;
}

.contact-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 25px;
  border: 2px solid #e006e0;
  background-color: rgba(58, 58, 58, 0.5);
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: rgba(58, 58, 58, 0.7);
  color: #fff;
  outline-color: #e006e0;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #e006e0;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #c005c0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Add your tablet-specific styles here */
  .contact-container {
    justify-content: flex-start;
    padding-top: 45px;
  }

  .contact-form {
    row-gap: 8px;
  }
}

@media screen and (min-width: 1024px) {
  /* Add your tablet-specific styles here */
  .contact-container {
    font-size: 1.5rem;
  }

  .contact-form {
    width: 35%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-group input {
    width: 237px;
  }

  .form-group select {
    width: 260px;
  }

  textarea {
    height: 150px;
    width: 515px;
    flex-grow: 4;
  }

  .submit-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  .contact-container {
    margin-top: 100px;
  }
}
