.projects-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  padding-top: 20px;
}

.projects-box-container {
  width: 77%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.project-box {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  border: 2px solid #e006e0;
  padding: 8px;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}

.service {
  font-size: 16px;
  font-weight: bold;
}

.project-img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: fill;
}

.details {
  width: 150px;
  border: 2px solid #ff007a;
  border-radius: 25px;
  padding: 8px;
  transition: all 0.3s ease;
  color: #ff007a;
  background: none;
  cursor: pointer;
}

.details:hover {
  background-color: #ff007a;
  color: white;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  touch-action: none; /* Prevents touch scrolling */
}

.modal-content {
  background-color: #121212;
  border: 2px solid #e006e0;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  color: white;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling in iOS */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ff007a;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.modal-section {
  margin-bottom: 20px;
}

.modal-section h4 {
  color: #ff007a;
  margin-bottom: 8px;
}

.modal-section ul {
  list-style-type: none;
  padding-left: 10px;
}

.modal-section li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 20px;
}

.modal-section li::before {
  content: '•';
  color: #ff007a;
  position: absolute;
  left: 0;
  font-size: 1.2em;
}

.project-link {
  color: #ff007a;
  text-decoration: underline;
  cursor: pointer;
}

/* Tablet (Portrait) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .projects-container {
    font-size: 1.5rem;
    padding-top: 80px;
    justify-content: flex-start;
  }

  .projects-box-container {
    width: 90%;
    height: auto;
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .project-box {
    width: 100%;
    height: auto;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .projects-container {
    font-size: 1.5rem;
    padding: 40px;
    height: auto;
  }

  .projects-box-container {
    width: 90%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-top: 40px;
  }

  .project-box {
    width: 100%;
    height: auto;
  }
}

/* Additional style to prevent body scrolling when modal is open */
body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 400px) {
  .projects-container {
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }
}
